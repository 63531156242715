import * as React from 'react'
import {
  ColorValue,
  DimensionValue,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native'

type Props = {
  height?: DimensionValue
  width?: DimensionValue
  backgroundColor?: ColorValue
  noShrink?: boolean
  style?: StyleProp<ViewStyle>
}

export function Spacer(props: Props) {
  const { height, width, backgroundColor } = props
  return (
    <View
      data-testid="spacer"
      accessibilityRole="none"
      style={[
        styles.base,
        { height, width, backgroundColor },
        props.noShrink && styles.noShrink,
        props.style,
      ]}
    />
  )
}

const styles = StyleSheet.create({
  base: {
    flexShrink: 1,
    flexGrow: 0,
    height: 1,
    width: 1,
    backgroundColor: 'rgba(0,0,0,0)',
  },
  noShrink: {
    flexShrink: 0,
  },
})
