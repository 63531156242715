import * as React from 'react'
import { View, ActivityIndicator, StyleSheet } from 'react-native'
import { Spacer } from '@components/Spacer'

type Props = {
  active?: boolean
}

export function ActivityFooter({ active }: Props = { active: false }) {
  return (
    <View style={styles.container}>
      <Spacer height={24} />
      {active && <ActivityIndicator size="small" />}
      <Spacer height={24} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
